<template>
  <div class="">
    <p v-if="!statusFlag">redirecting...</p>
    <vs-alert v-if="statusFlag" color="warning" title="Ошибка" active="true" class="mt-3">
      {{status}}.
    </vs-alert>

  </div>
</template>

<script>
export default {
  data(){
    return {
        sql_in: {
          username: ''
        },
        statusFlag: false,
    }
  },
mounted() {

    console.log('inside redirecst')
      //document.cookie = "access_token_habr=" + this.$route.params.token + ";path=/";

        var arr = this.$route.params.token.split(",");
      this.sql_in.username = arr[1]

      this.axios({
          method:'post',
          url: '' + this.$hostname + '/get-authority?access_token='+arr[0],
          data: this.sql_in,
      }).then( (res) => {
        console.log('status = ' + res.data)
        console.log( res.data)
    if (res.data.status === 'success'){

      console.log('status = ' + res.data.status)
      console.log(this.$cookies.keys())
      this.$cookies.set('access_token_skillrank',arr[0]);
      this.$cookies.set('username_skillrank',res.data.username);
      this.$cookies.set('authority_skillrank',res.data.authority);
      this.$cookies.set('confirmed_skillrank',res.data.confirmed);
      this.$cookies.set('id_skillrank',res.data.id);


          this.$store.commit('UPDATE_LOGGEDIN_STATE', 1);
          this.$store.commit('UPDATE_TOKEN', res.data.access_token);
          this.$store.commit('UPDATE_USERNAME', res.data.username);
          this.$store.commit('UPDATE_USER_ID', res.data.id);
          this.$store.commit('UPDATE_USER_AUTHORITY', res.data.authority);
            this.$store.commit('Update_justRegistered_State', false);



            this.$router.push({path:'/vector-search'})

          }  else {
              console.log(res.data)

              this.status = res.data.status
              this.statusFlag = true

            }
        }).catch((error) => {
          console.log('error')
          console.log(error)
          this.statusFlag = true
          this.status = 'Попробуйте еще раз.'
    });

}
}
</script>
